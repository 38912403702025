import React from 'react';
import { FaLinkedin, FaFacebookSquare } from 'react-icons/fa';
import JSONData from '../content/data.json';

const {
    profile: {
        social: { facebook, linkedin },
    },
} = JSONData;

const SocialIcons = ({ size = '2em' }) => (
    <div className="social">
        <a href={linkedin} target="_blank" rel="noreferrer" aria-label="Linkedin">
            <FaLinkedin size={size} />
        </a>{' '}
        <a href={facebook} target="_blank" rel="noreferrer" aria-label="Facebook">
            <FaFacebookSquare size={size} />
        </a>
    </div>
);

export default SocialIcons;
