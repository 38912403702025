import React from 'react';
// import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import JSONData from '../content/data.json';

// export const aboutQuery = graphql`
//     query AboutQuery {
//         site {
//             siteMetadata {
//                 profile {
//                     about
//                 }
//             }
//         }
//         # mobileImageTeam: file(relativePath: { eq: "team.jpg" }) {
//         #     childImageSharp {
//         #         fluid(maxWidth: 400, quality: 100) {
//         #             ...GatsbyImageSharpFluid_withWebp_noBase64
//         #         }
//         #     }
//         # }
//         # desktopImageTeam: file(relativePath: { eq: "team.jpg" }) {
//         #     childImageSharp {
//         #         fluid(maxWidth: 550, quality: 100) {
//         #             ...GatsbyImageSharpFluid_withWebp_noBase64
//         #         }
//         #     }
//         # }
//     }
// `;

const {
    profile: { about },
} = JSONData;

const About = () => (
    <section id="about" className="about">
        <div className="container">
            <div className="row">
                {/* <div className="col-sm-12 col-md-6"> */}
                <div className="col-12">
                    <h2 className="section-title">Jelmondatom</h2>
                    <div className="about-content">
                        <p className="description">
                            <span className="proverb">{about}</span>
                            <span className="proverb-source"> (Izlandi közmondás)</span>
                        </p>

                        <a className="btn btn-primary hero-cta" href="#contact" role="button">
                            Lépjen kapcsolatba
                        </a>
                    </div>
                </div>
                {/* <div className="col-sm-12 col-md-6">
                            <div className="about-photo">
                                <Img
                                    className="about-img"
                                    fluid={[
                                        mobileImageTeam.childImageSharp.fluid,
                                        {
                                            ...desktopImageTeam.childImageSharp.fluid,
                                            media: `(min-width: 900px)`,
                                        },
                                    ]}
                                    alt=""
                                />
                            </div>
                        </div> */}
            </div>
        </div>
    </section>
);

export default About;
