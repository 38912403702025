import React from 'react';
import { FaHome, FaGavel, FaBalanceScaleRight, FaSearchDollar, FaBriefcase } from 'react-icons/fa';
import JSONData from '../content/data.json';

const icons = {
    'balance-scale-right': FaBalanceScaleRight,
    home: FaHome,
    gavel: FaGavel,
    'search-dollar': FaSearchDollar,
    briefcase: FaBriefcase,
};
const { services } = JSONData;

const Services = () => (
    <section id="services">
        <div className="container">
            <h2 className="section-title">Miben segíthetünk önnek</h2>
            <div className="service-container">
                {services.map(service => (
                    <div className="service-item" key={service.title}>
                        {service.icon &&
                            React.createElement(icons[service.icon], {
                                className: 'service-icon',
                                size: '3em',
                            })}
                        <h3 className="service-title">{service.title}</h3>
                        {/* <p className="description">{service.description}</p> */}
                        <ul className="service-list">
                            {service.description.map(item => (
                                <li className="service-list__item" key={item.slice(3)}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

export default Services;
