import React from 'react';
// import { StaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import SocialIcons from './SocialIcons';
import ContactInfo from './ContactInfo';
import JSONData from '../content/data.json';

// export const profileQuery = graphql`
//     query HeroComponentQuery {
//         site {
//             siteMetadata {
//                 title
//                 profile {
//                     name
//                     title
//                     description
//                 }
//             }
//         }
//         # mobileImage: file(relativePath: { eq: "lawyer_profile.jpg" }) {
//         #     childImageSharp {
//         #         fluid(maxWidth: 400, quality: 100) {
//         #             ...GatsbyImageSharpFluid_withWebp_noBase64
//         #         }
//         #     }
//         # }
//         # desktopImage: file(relativePath: { eq: "lawyer_profile.jpg" }) {
//         #     childImageSharp {
//         #         fluid(maxWidth: 360, quality: 100) {
//         #             ...GatsbyImageSharpFluid_withWebp_noBase64
//         #         }
//         #     }
//         # }
//     }
// `;

const { profile } = JSONData;

const Hero = () => {
    return (
        // <StaticQuery
        //     query={profileQuery}
        //     render={({
        //         site: {
        //             siteMetadata: { profile },
        //         },
        //         // desktopImage,
        //         // mobileImage,
        //     }) => (
        <section id="home" className="hero">
            <div className="container">
                <div className="row">
                    {/* <div className="col-sm-12 col-md-4">
                                <Img
                                    fluid={[
                                        mobileImage.childImageSharp.fluid,
                                        {
                                            ...desktopImage.childImageSharp.fluid,
                                            media: `(min-width: 900px)`,
                                        },
                                    ]}
                                    className="avatar"
                                    alt={profile.name}
                                />
                            </div>
                            <div className="col-sm-12 col-md-8 hero-content"> */}
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>{profile.name}</h1>
                                <h2 className="job-title">{profile.title}</h2>
                                <SocialIcons />
                                <p className="description">{profile.description}</p>
                            </div>
                        </div>
                        <div>
                            <ContactInfo />
                            <div className="row">
                                <div className="col-12">
                                    <a className="btn btn-primary hero-cta" href="#contact" role="button">
                                        Elérhetőségek
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        //     )}
        // />
    );
};
export default Hero;
