import React from 'react';
import Helmet from 'react-helmet';
import JSONData from '../content/data.json';

const {
    seo: { title, description, author, keywords, url, lang },
    profile,
} = JSONData;

const Seo = () => (
    <Helmet>
        <html lang={lang} />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <title>{title}</title>
        {url && <meta name="identifier-url" content={url} />}

        <link rel="canonical" href={url} />

        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />

        <meta property="og:type" content="profile" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={profile.name} />
        {/* TODO */}
        {/* <meta property="og:image" content="" /> */}
        <meta property="profile:first_name" content={profile.firstName} />
        <meta property="profile:last_name" content={profile.lastName} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <script type="application/ld+json">{`
                    {
                        "@context": "https://schema.org",
                        "@type": "LegalService",
                        "name": "${profile.name}",
                        "url": "${url}",
                        "image": "",
                        "telephone": "${profile.contact.phone}",
                        "address": {
                          "@type": "PostalAddress",
                          "streetAddress": "Váralja 2.",
                          "addressLocality": "Pannonhalma",
                          "postalCode": "9090",
                          "addressCountry": "HU"
                        },
                        "openingHoursSpecification": [{
                          "@type": "OpeningHoursSpecification",
                          "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                          ],
                          "opens": "09:00",
                          "closes": "17:00"
                        },{
                          "@type": "OpeningHoursSpecification",
                          "dayOfWeek": "Friday",
                          "opens": "09:00",
                          "closes": "14:00"
                        }],
                        "sameAs": [
                          "${profile.social.facebook}",
                          "${profile.social.linkedin}"
                        ]
                      }
                `}</script>
    </Helmet>
);

export default Seo;
