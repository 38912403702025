import React from 'react';
import Seo from '../components/Seo';
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Office from '../components/Office';
import Testimonial from '../components/Testimonial';
import Footer from '../components/Footer';

const HomePage = () => (
    <>
        <Seo />
        <Header />
        <Hero />
        <About />
        <Services />
        <Testimonial />
        <Office />
        <Footer />
    </>
);

export default HomePage;
