import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { FaBars, FaTimes } from 'react-icons/fa';
import JSONData from '../content/data.json';

const {
    navigation: { menu, cta },
    profile: { name, title },
} = JSONData;

const Header = () => {
    const [isOpen, setOpen] = useState(false);

    let menuClass = 'menu navbar-nav';
    if (isOpen) {
        menuClass += ' active';
    }

    return (
        <header>
            <nav className="nav">
                <div className="nav-bar container">
                    <div className="navbar-brand">
                        <a href="/">
                            <StaticImage
                                width={65}
                                height={50}
                                layout="fixed"
                                placeholder="blurred"
                                src="../images/logo.png"
                                className="nav-logo"
                                alt="logo"
                            />
                        </a>
                        <span>{name}</span>
                        <span className="sub-title">{title}</span>
                    </div>
                    <div className="toggle">
                        <a
                            href="#0"
                            onClick={() => setOpen(!isOpen)}
                            className="menu-icon"
                            aria-label={isOpen ? 'Menü bezárása' : 'Menü kinyitása'}
                        >
                            {isOpen ? <FaTimes size="2em" /> : <FaBars size="2em" />}
                        </a>
                    </div>
                    <div className="navbar-collapse">
                        <ul className={menuClass}>
                            {menu.map(({ title, url }) => (
                                <li className="item" key={title}>
                                    <a href={url}>{title}</a>
                                </li>
                            ))}
                            <li className="item cta" key={cta.title}>
                                <a className="btn btn-primary" href={cta.url} role="button">
                                    {cta.title}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
