import React from 'react';
import SocialIcons from './SocialIcons';
import ContactInfo from './ContactInfo';
import JSONData from '../content/data.json';

const {
    services,
    seo,
    profile,
    navigation: { menu, cta },
} = JSONData;

const Footer = () => (
    <footer className="footer">
        <section id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-12 footer__col">
                        <h4>Dr. Mándli Ádám</h4>
                        <p className="description">{seo.description}</p>
                        <SocialIcons />
                    </div>
                    <div className="col-md-3 col-sm-12 footer__col">
                        <h4>Mivel foglalkozunk</h4>
                        <ul>
                            {services.map(service => (
                                <li key={service.title}>{service.title}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-12 footer__col">
                        <h4>Navigáció</h4>
                        <ul>
                            {menu.map(({ title, url }) => (
                                <li key={title}>
                                    <a href={url}>{title}</a>
                                </li>
                            ))}
                            <li key={cta.title}>
                                <a href={cta.url}>{cta.title}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-3 col-sm-12 footer__col">
                        <h4>Elérhetőség</h4>
                        <ContactInfo oneLiner={true} />
                    </div>
                </div>
                <div className="copyright row">
                    <div className="col-sm-12">
                        © {profile.name} {profile.title} {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </section>
    </footer>
);

export default Footer;
