import React, { lazy, Suspense } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import ContactInfo from './ContactInfo';
import JSONData from '../content/data.json';

const GoogleMapsLazy = lazy(() => import('./GoogleMap'));

const containerStyle = {
    width: '100%',
    height: '325px',
    borderRadius: '10px',
};

const {
    profile: {
        office: { openingHours },
    },
} = JSONData;

const Office = () => {
    const isSSR = typeof window === 'undefined';

    return (
        <section id="office">
            <div className="container">
                <h2 className="section-title">Irodánk</h2>
                {!isSSR && (
                    <Suspense fallback={<div />}>
                        <GoogleMapsLazy containerStyle={containerStyle} />
                    </Suspense>
                )}
                <div className="office-details row">
                    <div className="col-sm-12 col-md-6 col-lg-4 office-details__col">
                        <StaticImage
                            height={354}
                            placeholder="blurred"
                            src="../images/office.jpeg"
                            className="office-img"
                            alt="Ügyvédi iroda Pannonhalma, Váralja 2."
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 office-details__col">
                        <h3>Nyitvatartás</h3>
                        <div>
                            {openingHours.map(({ day, hours }) => (
                                <div key={day} className="opening-hours__row">
                                    <strong className="opening-hours__day">{day}:</strong>{' '}
                                    <span className="opening-hours__time">{hours}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 office-details__col" id="contact">
                        <h3>Elérhetőség</h3>
                        <ContactInfo oneLiner={true} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Office;
