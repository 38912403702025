import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import JSONData from '../content/data.json';

const { profile } = JSONData;

const ContactInfo = ({ oneLiner = false }) => {
    const classNames = {
        contactTitle: oneLiner ? 'col-sm-12' : 'col-sm-3',
        contactValue: oneLiner ? 'col-sm-12' : 'col-sm-9',
    };

    return (
        <>
            <div className="row contact-row">
                <div className={classNames.contactTitle}>
                    <FaPhone className="contact-icons" /> <strong>Telefon</strong>
                </div>
                <div className={classNames.contactValue}>
                    <a href={`tel:${profile.contact.phone}`}>{profile.contact.phone}</a>
                </div>
            </div>
            <div className="row contact-row">
                <div className={classNames.contactTitle}>
                    <FaEnvelope className="contact-icons" /> <strong>Email</strong>
                </div>
                <div className={classNames.contactValue}>
                    <a href={`mailto:${profile.contact.email}`}>{profile.contact.email}</a>
                </div>
            </div>
            <div className="row contact-row">
                <div className={classNames.contactTitle}>
                    <FaMapMarkerAlt className="contact-icons" /> <strong>Cím</strong>
                </div>
                <div className={classNames.contactValue}>{profile.office.address}</div>
            </div>
        </>
    );
};

export default ContactInfo;
