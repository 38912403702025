import React from 'react';
import { FaUser, FaQuoteRight } from 'react-icons/fa';
import JSONData from '../content/data.json';

const { testimonials } = JSONData;

const Testimonials = () => (
    <section id="testimonial">
        <div className="container">
            <h2 className="section-title">Ügyfeleink értékelései</h2>
            <div className="testimonials">
                {testimonials.map(({ name, text }) => (
                    <div className="testimonial" key={name}>
                        <FaQuoteRight size="3em" className="testimonial__quote" />
                        <div>
                            <FaUser size="2em" className="testimonial__thumbnail" />
                        </div>
                        <div>
                            <h3>{name}</h3>
                            <p className="description">{text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

export default Testimonials;
